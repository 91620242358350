import { Maybe } from '@/types';

export function resolveContentfulAspectRatio(mediaAspectRatio?: Maybe<string>) {
	switch (mediaAspectRatio) {
		case 'Standard (5:4)':
		case 'FiveToFour':
			return '5:4';
		case 'Square (1:1)':
		case 'OneToOne':
			return '1:1';
		case 'Portrait (9:16)':
			return '9:16';
		case 'Landscape (2:1)':
			return '2:1';
		case 'Landscape (16:9)':
			return '16:9';
		default:
			return '16:9';
	}
}
