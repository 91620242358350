import { FacilityFieldsFragment } from '@/graphql/__generated/sdk';
import { BrandThemeConfig, TOfficeInfo } from '@/types';
import { BffFacility } from '@/types/generated';

import { officePathByBrand } from '../officeURLByBrand/officeURLByBrand';

export const replaceUrlContentVars = (
	href: string,
	officeInfo: FacilityFieldsFragment | null,
	officeLocationInfo: TOfficeInfo | null,
	config: BrandThemeConfig,
	typeName?: string
) => {
	if (href.includes('[facilityUrl]') || typeName === 'FacilityDetailsPage' || typeName === 'ServiceDetailsPage') {
		const address: FacilityFieldsFragment['address'] | BffFacility['address'] =
			officeInfo?.address || officeLocationInfo?.address;
		if (address) {
			const facilityUrl = officePathByBrand(config.themeId, {
				stateStrOptions: {
					stateAbbreviation: address?.stateCode || '',
					state: '',
					city: address?.city || '',
				},
				cityAddressOptions: {
					city: address?.city || undefined,
					address: address?.address1 || undefined,
					zip: address?.zipCode || undefined,
				},
			});

			const facilityBasePath = config.features.otherApps.facilityDetailsBasePath;
			// TODO: Temporarily still support checking the href until we can refactor all the components to pass in the typeName as this href inlcude check isn't really needed with typeName checking support
			if (href.includes('[facilityUrl]')) {
				const regex = /\/?\[facilityUrl]\/?/;
				return href.replace(regex, `/${facilityBasePath}/${facilityUrl}/`);
			}

			if (typeName === 'FacilityDetailsPage') {
				return `/${facilityBasePath}/${facilityUrl}/`;
			}

			if (typeName === 'ServiceDetailsPage') {
				return `/${facilityBasePath}/${facilityUrl}${href}`;
			}
		}
		return href;
	}

	if (href.includes('[schedulingUrl]')) {
		if (officeInfo?.scheduling?.schedulingUrl) {
			return href.replace('[schedulingUrl]', officeInfo?.scheduling?.schedulingUrl);
		}
		return href.replace('[schedulingUrl]', `/${config.features.otherApps.facilityDetailsBasePath}`);
	}

	return href;
};
